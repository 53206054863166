import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { useUserState } from "./context/UserContext";
import Layout from "./components/Layout/Layout";
import Login from "./pages/login/Login";
import Error from "./pages/error/Error";
import React from "react";

export default function App() {
  const { authenticated } = useUserState();

  const PublicRoute = ({ component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={props =>
          authenticated ? (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  };

  const PrivateRoute = ({ component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={props =>
          authenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect to="/login" />
          )
        }
      />
    );
  };

  return (
    <BrowserRouter>
      <Switch>
        <PublicRoute path="/login" component={Login} />
        <PrivateRoute path="/" component={Layout} />
        <Route component={Error} />
      </Switch>
    </BrowserRouter>
  );
}
