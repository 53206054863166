import PageTitle from "../../components/PageTitle/PageTitle";
import React from "react";

export const Dashboard = props => {
  return (
    <>
      <PageTitle title="Dashboard" button="Latest Reports" />
    </>
  );
};
