import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import { toast, ToastContainer } from "react-toastify";
import CssBaseline from "@material-ui/core/CssBaseline";
import logo from "../../components/Header/finance.svg";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router-dom";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import queryString from "query-string";
import useStyles from "./styles";

import "./Login.css";
import {
  signIn,
  signInWithGoogle,
  useUserDispatch,
  getURlAuthorizationResource,
} from "../../context/UserContext";

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://finance.queiroz.dev/">
        Finance C01DE
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

const Login = props => {
  const classes = useStyles();
  const userDispatch = useUserDispatch();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [loading, setLoading] = useState(false);

  const [googleAuth] = useState(queryString.parse(props.location.hash));

  const handleGoogleSign = () => {
    getURlAuthorizationResource(userDispatch, props.history, () => {
      setLoading(true);
    })
      .catch(handlerError)
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmit = event =>
    signIn(
      {
        username,
        password,
      },
      userDispatch,
      props.history,
      () => {
        event.preventDefault();
        setLoading(true);
      },
    )
      .catch(handlerError)
      .finally(() => {
        setLoading(false);
      });

  const handlerError = e => {
    console.error(e);
    toast("Invalid credential!");
  };

  const GoogleSignIcon = () => {
    return (
      <svg width="40" height="40">
        <g
          id="GoogleButton"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <rect
            x="0"
            y="0"
            width="40"
            height="40"
            rx="2"
            className={classes.cssf09764}
          />
          <g transform="translate(10.5, 10.5) scale(1.1)">
            <path
              d="M17.64,9.20454545 C17.64,8.56636364 17.5827273,7.95272727  17.4763636,7.36363636 L9,7.36363636 L9,10.845 L13.8436364,10.845 C13.635,11.97 13.0009091,12.9231818 12.0477273,13.5613636 L12.0477273,15.8195455 L14.9563636,15.8195455 C16.6581818,14.2527273 17.64,11.9454545 17.64,9.20454545 L17.64,9.20454545 Z"
              fill="#4285F4"
            />
            <path
              d="M9,18 C11.43,18 13.4672727,17.1940909 14.9563636,15.8195455 L12.0477273,13.5613636 C11.2418182,14.1013636 10.2109091,14.4204545 9,14.4204545 C6.65590909,14.4204545 4.67181818,12.8372727  3.96409091,10.71 L0.957272727,10.71 L0.957272727,13.0418182  C2.43818182,15.9831818 5.48181818,18 9,18 L9,18 Z"
              fill="#34A853"
            />
            <path
              d="M3.96409091,10.71 C3.78409091,10.17 3.68181818,9.59318182 3.68181818,9  C3.68181818,8.40681818 3.78409091,7.83 3.96409091,7.29 L3.96409091,4.95818182  L0.957272727,4.95818182 C0.347727273,6.17318182 0,7.54772727 0,9 C0,10.4522727  0.347727273,11.8268182 0.957272727,13.0418182 L3.96409091,10.71 L3.96409091,10.71 Z"
              fill="#FBBC05"
            />
            <path
              d="M9,3.57954545 C10.3213636,3.57954545 11.5077273,4.03363636 12.4404545,4.92545455  L15.0218182,2.34409091 C13.4631818,0.891818182 11.4259091,0 9,0 C5.48181818,0  2.43818182,2.01681818 0.957272727,4.95818182 L3.96409091,7.29 C4.67181818,5.16272727  6.65590909,3.57954545 9,3.57954545 L9,3.57954545 Z"
              fill="#EA4335"
            />
            <path d="M0,0 L18,0 L18,18 L0,18 L0,0 Z" id="Shape" />
          </g>
        </g>
      </svg>
    );
  };

  useEffect(() => {
    signInWithGoogle(
      googleAuth.state
        ? {
            state: googleAuth.state,
            code: googleAuth.access_token,
          }
        : undefined,
      userDispatch,
      props.history,
      () => setLoading(true),
    )
      .catch(handlerError)
      .finally(() => setLoading(false));
  }, [userDispatch, googleAuth, props.history]);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper + " login"}>
        <ToastContainer />
        <Grid container className={classes.logoConteiner}>
          <img className={classes.logoImage} src={logo} alt="logo" />
          <span className={classes.logoText}>sign in</span>
          <span className={classes.logoSubText}>
            to continue in finance code
          </span>
        </Grid>
        <form
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
          className={classes.form}
        >
          <FormControl className={classes.form} required={true}>
            <TextField
              id="username"
              name="username"
              required
              fullWidth
              autoFocus
              margin="normal"
              variant="outlined"
              label="Email"
              placeholder="Email"
              autoComplete="off"
              onChange={({ target }) => setUsername(target.value)}
              error={loading && (username === "" || username === undefined)}
            />
          </FormControl>
          <FormControl className={classes.form}>
            <TextField
              id="password"
              required
              fullWidth
              margin="normal"
              name="password"
              type="password"
              label="Password"
              variant="outlined"
              placeholder="Password"
              autoComplete="off"
              onChange={({ target }) => setPassword(target.value)}
              error={loading && (password === "" || password === undefined)}
            />
          </FormControl>
          <Button
            id="signWithgoogle"
            type="button"
            color="secondary"
            variant="contained"
            disabled={loading}
            startIcon={<GoogleSignIcon />}
            className={classes.buttonSignGoogle}
            onClick={() => handleGoogleSign()}
          >
            <div className={classes.cssvhl90e}>
              <div className={classes.cssf04y3h}>Sign in with Google</div>
            </div>
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </Button>
          <Button
            id="login"
            type="submit"
            color="primary"
            variant="contained"
            disabled={loading}
            className={classes.buttonSignLocal}
          >
            <div className={classes.cssvhl90e}>
              <div className={classes.cssf04y3h}>Sign In</div>
            </div>
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8} className={classes.loginFooter}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default withRouter(Login);
