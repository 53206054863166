import React, { createContext, useContext, useReducer } from "react";
import { UserService } from "../services/user.service";

const UserStateContext = createContext({});
const UserDispatchContext = createContext({});

const useAppReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, ...action.payload };
    case "SIGN_OUT_SUCCESS":
      return { ...state, ...action.payload };
    default: {
      return state;
    }
  }
};

const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    useAppReducer,
    {
      authenticated: !!localStorage.getItem("@token"),
      user: JSON.parse(localStorage.getItem("@user")),
    },
    args => {
      return {
        ...args,
      };
    },
  );

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
};

const useUserState = () => {
  const context = useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
};

const useUserDispatch = () => {
  const context = useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
};

const signIn = (credential, dispatch, history, callback) => {
  callback();

  const { username, password } = credential;

  return UserService.signIn(username, password).then(({ login }) =>
    processSignIn(login, dispatch, history),
  );
};

const getURlAuthorizationResource = (dispatch, history, callback) => {
  callback();
  return UserService.getURlAuthorizationResource(window.location.origin).then(
    ({ authorization }) => {
      return (window.location.href = authorization.url);
    },
  );
};

const signInWithGoogle = async (credential, dispatch, history, callback) => {
  if (credential === null || credential === undefined) {
    return;
  }

  callback();

  return processSignIn({ access_token: credential.code }, dispatch, history);
};

const processSignIn = async ({ access_token }, dispatch, history) =>
  UserService.getUserInformation(access_token).then(({ me }) => {
    dispatch({
      type: "LOGIN_SUCCESS",
      payload: {
        authenticated: true,
        user: me,
      },
    });
    localStorage.setItem("@user", JSON.stringify(me));
    localStorage.setItem("@token", access_token);
    history.push("/dashboard");
  });

const signOut = (dispatch, history) => {
  localStorage.removeItem("@token");
  localStorage.removeItem("@user");
  dispatch({
    type: "SIGN_OUT_SUCCESS",
    payload: {
      authenticated: false,
    },
  });
  history.push("/");
};

export {
  UserProvider,
  useUserState,
  useUserDispatch,
  signIn,
  signInWithGoogle,
  signOut,
  getURlAuthorizationResource,
};
