import green from "@material-ui/core/colors/green";
import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: "24px", //theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logoConteiner: {
    display: "contents",
    position: "relative",
    alignItems: "center",
  },
  logoImage: {
    width: "172px",
  },
  logoText: {
    fontSize: "48px",
  },
  logoSubText: {
    fontSize: "24px",
  },
  buttonSuccess: {
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonSignSvg: {
    width: "24px",
    height: "24px",
  },
  buttonSignText: {
    width: "100%",
    display: "contents",
    alignItems: "inherit",
    justifyContent: "center",
    background: "#4285f4",
    whiteSpace: "nowrap",
    marginLeft: "0",
    marginRight: "0",
  },
  buttonSignLocal: {
    flexDirection: "row",
    display: "flex",
    cursor: "pointer",
    padding: "0 0 0 4px",
    textTransform: "none",
    // borderColor: "rgb(66, 133, 244)",
    border: "1px solid transparent",
    borderImage: "initial",
    borderStyle: "solid",
    borderRadius: "2px",
    borderWidth: "1px",
    margin: theme.spacing(3, 0, 2),
    float: "right",
    height: "42px",
    width: "49%",
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  buttonSignGoogle: {
    // backgroundColor: "rgb(66, 133, 244)",
    // boxSizing: "content-box",
    flexDirection: "row",
    display: "flex",
    cursor: "pointer",
    padding: "0 0 0 4px",
    textTransform: "none",
    // borderColor: "rgb(66, 133, 244)",
    border: "1px solid transparent",
    borderImage: "initial",
    borderStyle: "solid",
    borderRadius: "2px",
    borderWidth: "1px",
    margin: theme.spacing(3, 0, 2),
    float: "left",
    height: "42px",
    width: "49%",
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  cssvhl90e: {
    "-webkit-box-flex": "1",
    flexGrow: "1",
    height: "40px",
  },
  cssf04y3h: {
    color: "rgb(255, 255, 255)",
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "40px",
    height: "40px",
  },
  cssf09764: {
    fill: "rgb(255, 255, 255)",
  },
  loginFooter: {
    marginTop: "36px",
  },
}));
