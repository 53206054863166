import { Typography } from "../Wrappers/Wrappers";
import { Button } from "@material-ui/core";
import useStyles from "./styles";
import React from "react";

const PageTitle = props => {
  const classes = useStyles();
  return (
    <div className={classes.pageTitleContainer}>
      <Typography className={classes.typo} variant="h1" size="sm">
        {props.title}
      </Typography>
      {props.button && (
        <Button
          classes={{ root: classes.button }}
          variant="contained"
          size="large"
          color="secondary"
        >
          {props.button}
        </Button>
      )}
    </div>
  );
};

export default PageTitle;
