import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import { useLayoutState } from "../../context/LayoutContext";
import { Dashboard } from "../../pages/dashboard/Dashboard";
import { Header } from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import classnames from "classnames";
import useStyles from "./styles";
import React from "react";

const Layout = props => {
  const classes = useStyles();
  const layoutState = useLayoutState();

  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} />
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar} />
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
            <Route exact path="/dashboard" component={Dashboard} />
          </Switch>
        </div>
      </>
    </div>
  );
};

export default withRouter(Layout);
