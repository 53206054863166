import api from "./base.service";

const getURlAuthorizationResource = async redirectUri => {
  return api
    .post("/", {
      query: `
          query ($uri: String!) {
            authorization(uri: $uri) {
              url
            }
          }
      `,
      variables: {
        uri: redirectUri,
      },
    })
    .then(({ data }) => data.data);
};

const signIn = async (username, password) => {
  return api
    .post("/", {
      query: `
          mutation ($username: String!, $password: String!) {
            login(credential: {username: $username, password: $password}) {
              access_token
              token_type
              expires_in
            }
          }
      `,
      variables: {
        username,
        password,
      },
    })
    .then(({ data }) => data.data);
};

const getUserInformation = async token => {
  return api
    .post(
      "/",
      {
        query: `
          query {
            me {
              id
              name
              email
              picture
            }
          }
      `,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then(({ data }) => data.data);
};

const signInWithGoogle = async (state, code) => {
  return api
    .post("/", {
      query: `
          mutation ($state: String!, $code: String!) {
            authorization(credential: {state: $state, code: $code}) {
              access_token
              token_type
              expires_in
            }
          }          
      `,
      variables: {
        state,
        code,
      },
    })
    .then(({ data }) => data.data);
};

export const UserService = {
  signIn,
  signInWithGoogle,
  getURlAuthorizationResource,
  getUserInformation,
};
