import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_URL_FINANCE_API,
});

api.interceptors.request.use(async config => {
  console.info(process.env.REACT_APP_VERSION);
  console.info(process.env.REACT_APP_NAME);
  const token = localStorage.getItem("token");
  if (
    (token && config.headers.Authorization === "") ||
    config.headers.Authorization === undefined
  ) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;
